window.utui.util = window.utui.util || {};
utui.util.storage = {
    /* persist a key/value pair to the localStorage if it's supported, else fall back to using cookies */
    storeLocal(key, value) {
        // check to see if localStorage is supported
        if (typeof window.localStorage !== "undefined") {
            localStorage[key] = value;
        } else {
            // localStorage is not supported, fallback to cookie
            this.cookies.setItem(key, value);
        }
    },
    /* load the value of a key from localStorage if it's supported, else use cookies */
    loadLocal(key) {
        // check to see if localStorage is supported
        if (typeof window.localStorage !== "undefined") {
            return localStorage[key];
        }
        // localStorage is not supported, fallback to cookie
        this.cookies.getItem(key);
    },
    /* unset a key/value pair from the localStorage if it's supported, else fall back to using cookies */
    removeLocal(key) {
        // check to see if localStorage is supported
        if (typeof window.localStorage !== "undefined") {
            localStorage.removeItem(key);
        } else {
            // localStorage is not supported, fallback to cookie
            this.cookies.removeItem(key);
        }
    },
    cookies: {
        getItem(key) {
            if (!key) return null;
            return decodeURIComponent(
                document.cookie.replace(
                    new RegExp(
                        "(?:^|.*;\\s*)" +
                            decodeURIComponent(key).replace(/[\-\.\+\*]/g, "\\$&") +
                            "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"
                    ),
                    "$1"
                )
            );
        },
        setItem(key, value, end, path, domain, secure) {
            if (!key) return;

            var expires = "";
            document.cookie =
                key +
                "=" +
                encodeURIComponent(value) +
                expires +
                (domain ? "; domain=" + domain : "") +
                (path ? "; path=" + path : "") +
                (secure ? "; secure" : "");
        },
        removeItem(key, path, domain) {
            document.cookie =
                encodeURIComponent(key) +
                "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
                (domain ? "; domain=" + domain : "") +
                (path ? "; path=" + path : "");
        }
    }
};
