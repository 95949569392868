// NOTE -- This feature is deprecated and future early access should be done through the Experiments feature
window.common = window.common || {};
common.abTest = {
    platforms: {
        tiq: '<span data-i18n="login.ab_test.tealium_iq"></span>',
        as: '<span data-i18n="login.ab_test.tealium_audiencestream"></span>'
    },

    // list of ab test features
    features: {
        /* Example: */
        /*
		demo_mode: {
			supported_platforms: ['tiq', 'as'],
			name: '<span data-i18n="login.ab_test.options.demo_mode.name"></span>',
			description: '<span data-i18n="login.ab_test.options.demo_mode.description"></span>',
			internalUseOnly: false,
			enable: function (platform) {
				if (platform === 'tiq') {
					utui.util.setSession = function () {
						clearTimeout(utui.sessionid);
						clearTimeout(utui.sessionaction);
					}
				}
			}
		}
		*/
        users: {
            supported_platforms: ["as"],
            name: '<span data-i18n="login.ab_test.options.users.name"></span>',
            description: '<span data-i18n="login.ab_test.options.users.description"></span>',
            internalUseOnly: false,
            enable() {}
        }
    }
};
