/**
 *   Utui wrapper for a i18next library
 */
window.localize = (function () {
    // Private API
    var _supportedLanguages = {
        "en-US": { name: "English", translation: "English", earlyAccess: false },
        "fr-FR": { name: "Français", translation: "French", earlyAccess: false },
        "de-DE": { name: "Deutsch", translation: "German", earlyAccess: false },
        "ja-JP": { name: "日本語", translation: "Japanese", earlyAccess: false },
    };
    var _defaultLang = "en-US";
    var _cookieName = "i18next";

    // ----------------------------------------------------------------
    // Initilizes the i18next library with some predefined default options
    function _init(options, cb) {
        var defaults = {
            resGetPath: "locales/__lng__/__ns__.json?rnd=" + Math.random(), // Path to locales
            lng: _getLang(), // Our own implementation of getting the users language from cookie or browser
            useCookie: true, // Looks up the specified language via the i18n cookie
            fallbackLng: false, // Set to false so the library does not load the dev fallback json file
            load: "current", // This prevents the loading of just the language code (i.e.: en.json for en-US.json)
            useDataAttrOptions: true, // Allows for data-i18n-options
            selectorAttr: "data-i18n", // Allows us to define our own lookup (data-i18n is default)
        };

        options = options || {};
        $.extend(defaults, options);

        i18n.init(defaults, function () {
            // if key 'smartling' is not found then default to engligh
            if (!_t("smartling")) {
                // If language not supported then default to english
                i18n.setLng(_defaultLang, cb);
            } else {
                if (cb) cb();
            }
        });
    }

    // ----------------------------------------------------------------
    // Gets the users language from the cookie or browser. Defaults to english if not supported
    function _getLang() {
        // First check if cookie is supported
        var lang = _getSupportedLang($.cookie(_cookieName));
        var isEarlyAccess;

        // If cookie value not supported, let's check if the users browser language is supported
        if (!lang) {
            lang = window.navigator.languages
                ? window.navigator.languages[0]
                : window.navigator.language || window.navigator.userLanguage;
            lang = _getSupportedLang(lang);

            if (_supportedLanguages[lang]) {
                // If this is an early-access feature (not complete yet) then don't return that browser language
                isEarlyAccess = _supportedLanguages[lang].earlyAccess;
            }

            // If browser language not supported then set lang to english as the default
            if (!lang || isEarlyAccess) {
                lang = _defaultLang;
            }
        }
        return lang;
    }

    // ----------------------------------------------------------------
    // This will return the supported language by adding the country code if needed ('en' -'en-US');
    function _getSupportedLang(lang) {
        lang = lang || "";
        for (var key in _supportedLanguages) {
            var langs = [key.toLowerCase(), key.split("-")[0]];
            if ($.inArray(lang.toLowerCase(), langs) > -1) return key;
        }
        return "";
    }

    // ----------------------------------------------------------------
    // Returns the value found for the key specified.
    // If no value is found this will return an empty string rather than the key specified.
    // If you want to return a default value then add {'defaultValue':'my default value'} as the options param
    function _t(key, options) {
        var value = i18n.translate.apply(this, arguments);
        // Do not return the key as the value if not found
        if (key === value) {
            value = "";
        }

        if (options) {
            // Lowercase value
            if (options.lcase) {
                value = value.toLowerCase();

                // Uppercase value
            } else if (options.ucase) {
                value = value.toUpperCase();

                // Capitalize the first letter of every word
            } else if (options.initCaps) {
                var arrStr = value.split(" ");
                for (var i = 0; i < arrStr.length; i++) {
                    arrStr[i] = arrStr[i].charAt(0).toUpperCase() + arrStr[i].substring(1);
                }
                value = arrStr.join(" ");

                // Convert to array if specified
            } else if (options.toArray) {
                value = value.split("\n");
            }
        }

        return value;
    }

    // ----------------------------------------------------------------
    // Replaces the text in the specified selector and all of its children using that have the data-i18n attribute
    function _convert(selector, options) {
        $(selector).i18n(options);
    }

    // ----------------------------------------------------------------
    // This will replace the text within the JSON structure and return it
    function _convertJSON(json) {
        var translated = {};
        for (var key in json) {
            var translatedKey = _t(key) || key;
            var translatedValue = _t(json[key]) || json[key];
            translated[translatedKey] = translatedValue;
        }
        return translated;
    }

    // ----------------------------------------------------------------
    // Gets the localized regular expression string and converts to regular expression object
    function _regX(key) {
        var reg = _t(key);

        if (reg) {
            // Get the modifiers if any
            var modifiers = reg.substring(reg.lastIndexOf("/") + 1, reg.length);
            // Remove the modifiers and /
            reg = reg.substring(1, reg.length - modifiers.length - 1);
            if (reg) {
                return new RegExp(reg, modifiers);
            }
        }
        return null;
    }

    // ----------------------------------------------------------------
    // Returns the supported languages object
    function _getSupportedLanguages() {
        return _supportedLanguages;
    }

    // ----------------------------------------------------------------
    // Returns the language code
    function _getCode() {
        return i18n.lng();
    }

    // ----------------------------------------------------------------
    // Returns the language (English, French...)
    function _getLanguage(code) {
        code = code || _getCode();
        return _supportedLanguages[code].name;
    }

    // ----------------------------------------------------------------
    function _setLanguage() {
        i18n.setLng.apply(this, arguments);
    }

    // ----------------------------------------------------------------
    function _setCookie(code) {
        $.cookie(_cookieName, code);
    }

    // ----------------------------------------------------------------
    function _isEarlyAccess() {
        var code = _getCode() || "en-US";
        return _supportedLanguages[code].earlyAccess;
    }

    // Public API
    var _public = {
        // ------------------------------------------------------------
        init: _init,
        get: _t,
        t: _t,
        convert: _convert,
        convertJSON: _convertJSON,
        regX: _regX,
        getSupportedLanguages: _getSupportedLanguages,
        getCode: _getCode,
        getLanguage: _getLanguage,
        setLanguage: _setLanguage,
        setCookie: _setCookie,
        isEarlyAccess: _isEarlyAccess,
        getLangCode: _getLang,
    };

    return _public;
})();
