window.utui = window.utui || {};
utui.earlyAccess = (function () {
    "use strict";

    try {
        // Subscribe to the profile loaded event so we can re-show the icons if needed
        // Subscribe to the profile loaded event so we can enable AB tests if needed
        utui.util.pubsub.subscribe(utui.constants.profile.LOADED, _onProfileLoaded);
        utui.util.pubsub.subscribe(utui.constants.help.INTRO_INIT, _showFeature);
    } catch (err) {}

    // Private API
    var _items = {
        localize: {
            isEarlyAccess: localize.isEarlyAccess,
            icon: {
                tooltip: localize.t("tiq.early_access.tooltip_language"),
                className: "",
                add($icon, id) {
                    $icon.insertBefore(".admin-menu-name");
                    _addTooltip(id, "", $("#adminMenu"));
                }
            }
        }
    };

    // ----------------------------------------------------------------
    // list of ab test features
    var _ab_test_features = common.abTest.features;
    var _platforms = common.abTest.platforms;
    var _enabledABTests = [];

    // ----------------------------------------------------------------
    function _onProfileLoaded() {
        _showIcons();
        _enableABTests();
    }

    // ----------------------------------------------------------------
    function _addTooltip(id, gravity, $context) {
        var obj = {};
        obj[id] = gravity || { gravity: "n" };
        utui.util.addTooltip(obj, $context);
    }

    // ----------------------------------------------------------------
    function _init() {
        // Append abTest view always so that abTest can be remembered next time
        $("#abTest").append(_view.login());
        _bindUI.login();

        // Show ab test settings button on login screen
        if (window.location.href.indexOf("?") > -1 && !utui.util.getParameterByName("tlc")) {
            $("#abTestBtn").fadeIn();
        }

        // remember last enabled ab Tests
        var abTest = utui.util.storage.loadLocal("ab_test");
        if (typeof abTest !== "undefined" && !$.isEmptyObject(abTest)) {
            _enabledABTests = abTest.split("&");
            var parts;
            var name;
            var feature;
            for (var i = 0; i < _enabledABTests.length; i++) {
                parts = _enabledABTests[i].split(".");
                name = parts[1];
                feature = _ab_test_features[name];
                $("#" + name).prop("checked", true);
            }
        }
    }

    // ----------------------------------------------------------------
    function _enableABTests() {
        var abTest = utui.util.storage.loadLocal("ab_test");

        if (typeof abTest !== "undefined" && _.size(abTest)) {
            _enabledABTests = abTest.split("&");

            var parts;
            var platform;
            var feature;
            for (var i = 0; i < _enabledABTests.length; i++) {
                parts = _enabledABTests[i].split(".");
                platform = parts[0];
                feature = _ab_test_features[parts[1]];

                if (!feature) {
                    console.warn("WARNING: Early Access feature (" + _enabledABTests[i] + ") does not exist.");
                } else if (!feature.internalUseOnly || utui.login.email.indexOf("@tealium.com") > 0) {
                    if (platform === "tiq") {
                        _ab_test_features[parts[1]].enable(platform);
                    }
                }
            }
        }

        utui.util.pubsub.publish(utui.constants.lifecycle.AB_TESTS_LOADED);
    }

    // ----------------------------------------------------------------
    // This function is made public; Should typically avoid using this function unless necessary - try to add to early access _items instead
    function _showIcon(obj, key) {
        // Display the icon if needed
        if (obj.icon && obj.icon.add) {
            var earlyAccessLangID = "early-access-" + key;
            var $icon = obj.icon ? _view.icon(earlyAccessLangID, obj.icon.tooltip, obj.icon.className) : {};

            obj.icon.add($icon, earlyAccessLangID);
        }
    }

    // ----------------------------------------------------------------
    // Display early access icons if needed
    function _showIcons() {
        _destroy();

        var customToolTipObj = {};
        for (var key in _items) {
            var obj = _items[key];

            // If this is an early access item...
            if (!obj.hasOwnProperty("isEarlyAccess") || obj.isEarlyAccess()) {
                _showIcon(obj, key);
            }
        }
    }

    // ----------------------------------------------------------------
    function _showFeature(tab) {
        var obj = _items[tab];
        if (!obj) {
            return;
        }

        // If this is an early access item...
        if (!obj.hasOwnProperty("isEarlyAccess") || obj.isEarlyAccess()) {
            // Display the feature if needed
            if (obj.feature && obj.feature.add) {
                var $feature = _view.feature();

                obj.feature.add($feature);
            }
        }
    }

    // ----------------------------------------------------------------
    var _view = {
        // ------------------------------------------------------------
        // Returns the early access icon
        icon(id, title, className) {
            return $(
                '<img id="' +
                    id +
                    '" class="early-access-icon ' +
                    (className || "") +
                    '" title="' +
                    title +
                    '" src="img/early-access-icon-small.png" />'
            );
        },

        // ------------------------------------------------------------
        feature(className) {
            var v = "";
            v += '<div class="early-access-feature ' + (className || "") + '">';
            v += '	<img src="img/early-access-ticket-large.png" align="left">';
            v += localize.t("tiq.early_access.feature_intro", {
                email:
                    '<a class="email-link" href="#" onclick="window.location.href=\'mailto:earlyaccess@tealium.com?subject=Early Access Feature Verify Data Layer\'">earlyaccess@tealium.com</a>'
            });
            v += "</div>";
            return $(v);
        },

        // ------------------------------------------------------------
        login() {
            var v = "";

            v += '<header><div class="bodyHeader-logo"></div></header>';
            v += '<div class="content-body">';
            v += '	<i class="icon-info-sign warning"></i>';
            v +=
                '	<div class="ab-test-disclaimer">' +
                localize.t("login.ab_test.disclaimer", {
                    url:
                        '<a href="' +
                        common.community.early_access +
                        '" target="_blank">' +
                        localize.t("login.ab_test.early_access_forum") +
                        "</a>"
                }) +
                "</div>";

            v += "	<div>";
            v += '		<div class="iq-ab-test">';
            v += "			<h3>" + localize.t("login.ab_test.available_features") + "</h3>";
            v += _view.listItems();
            v += "		</div>";
            v += "	</div>";
            v += "</div>";
            v += '<div class="content-footer">';
            v +=
                '	<button class="btn btn-primary pull-right" id="abTestApplyBtn">' +
                localize.t("tiq.dialog.button.ok") +
                "</button>";
            v += "</div>";

            return v;
        },

        // ------------------------------------------------------------
        listItems() {
            if (!Object.keys(_ab_test_features).length) {
                return _view.noItemsAvailable();
            }

            var feature;
            var platforms;
            var v = "";
            v += "<ul>";
            for (var key in _ab_test_features) {
                feature = _ab_test_features[key];
                platforms = feature.supported_platforms;
                v += "	<li>";
                v +=
                    '		<input type="checkbox" name="' +
                    key +
                    '" id="' +
                    key +
                    '" data-platforms="' +
                    platforms.join(",") +
                    '" value="y">';
                v += '		<label for="' + key + '">';
                v += '			<span class="title">' + feature.name + "</span>";
                v += "			<div>" + feature.description + "</div>";
                v += "		</label>";
                v += "	</li>";
            }
            v += "</ul>";
            return v;
        },

        // ------------------------------------------------------------
        noItemsAvailable() {
            return '<div><i class="icon-wrench"></i> ' + localize.t("login.ab_test.no_items_available") + "</div>";
        }
    };

    // ----------------------------------------------------------------
    var _bindUI = {
        // ------------------------------------------------------------
        login() {
            $("#abTestApplyBtn")
                .off("click")
                .on("click", function () {
                    $("#abTest").removeClass("selected").fadeOut("fast");
                });
        }
    };

    // ----------------------------------------------------------------
    // Remove all early access icons
    function _destroy() {
        $(".early-access-icon").remove();
    }

    // Public API
    // ----------------------------------------------------------------
    var _public = {
        init: _init,
        destroy: _destroy,

        // ------------------------------------------------------------
        isEnabled(abTestParam) {
            var enabled = _enabledABTests.indexOf(abTestParam) > -1;
            if (enabled) {
                // check for internal use only AB test
                var parts = abTestParam.split(".");
                if (!common.abTest.features[parts[1]].internalUseOnly || utui.login.email.indexOf("@tealium.com") > 0) {
                    return true;
                }
            }
            return false;
        },
        showIcon: _showIcon
    };

    return _public;
})();
