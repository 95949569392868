window.utui = window.utui || {};
utui.mfaSetup = (function () {
    "use strict";

    // Private API
    var _namespace = "mfa_setup";
    var _selectedOS = "android";
    var _url = {
        mfa_setup: "/urest/users/$$username$$/mfa/setup",
        mfa_verify: "/urest/users/$$username$$/mfa/verification"
    };
    var _operating_systems = {}; // setup in init method
    var _countdownSeconds = 10;
    var _refreshIntervalId;

    // ----------------------------------------------------------------
    function _init() {
        // Need to be in init so localized text is available
        _operating_systems = {
            android: {
                name: localize.t("login.mfa_setup.operation_systems.android"),
                appStore: localize.t("login.mfa_setup.google_play_store"),
                url: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            },
            iphone: {
                name: localize.t("login.mfa_setup.operation_systems.iPhone"),
                appStore: localize.t("login.mfa_setup.app_store"),
                url: "https://itunes.apple.com/us/app/google-authenticator/id388497605"
            },
            windows: {
                name: localize.t("login.mfa_setup.operation_systems.windows"),
                appStore: localize.t("login.mfa_setup.windows_app_store"),
                url: "https://www.microsoft.com/en-us/store/apps/authenticator/9wzdncrfj3rj"
            },
            none: {
                name: localize.t("login.mfa_setup.operation_systems.none")
            }
        };

        // Show MFA setup flow on login screen
        var $mfaSetup = $("#mfaSetup");
        $mfaSetup.html(_view.selectOperatingSystem());
        _bindUI.selectOperatingSystem();
        $mfaSetup.fadeIn("slow", function () {
            $(this).addClass("selected");
        });
    }

    // ----------------------------------------------------------------
    // redirect to login screen
    function _redirect() {
        clearInterval(_refreshIntervalId);
        $("#mfaSetup").fadeOut(function () {
            $(this).removeClass("selected");

            // Display the MFA token input field
            var $mfaTokenChbx = $("#mfa_token_chbx");
            if (!$mfaTokenChbx.is(":checked")) {
                $mfaTokenChbx.trigger("click");
            }

            // Set focus on token field
            $("#mfa_token").focus();
        });
    }

    // ----------------------------------------------------------------
    function _countdownTimer(namespace, numSeconds, callback) {
        var $el = $("#" + namespace).find(".countdown_timer");
        var seconds = numSeconds || 5;

        // Initialize
        $el.html(seconds);

        _refreshIntervalId = setInterval(function (e) {
            seconds--;

            $el.html(seconds);
            if (seconds <= 0) {
                clearInterval(_refreshIntervalId);
                if (callback) {
                    callback();
                }
            }
        }, 1000);
    }

    // ------------------------------------------------------------
    function urestEmptyDataFilter(data, type) {
        if (type == "json" && _.isEmpty(data)) {
            data = "{}";
        }
        return data;
    }

    function convertsOverride() {
        var converters = _.clone($.ajaxSettings.converters);
        converters["text json"] = function (data) {
            if (_.isEmpty(data)) {
                data = "{}";
            }
            return JSON.parse(data);
        };
        return converters;
    }

    // ------------------------------------------------------------
    function _sendRequest(type, url, data, options, success, error) {
        var settings = $.extend(
            {
                url,
                type,
                dataType: "json",
                dataFilter: urestEmptyDataFilter,
                converters: convertsOverride(),
                success,
                error,
                cache: false
            },
            options
        );

        if (!$.isEmptyObject(data)) {
            settings.data = data;
        }
        $.ajax(settings);
    }
    // function _sendRequest(type, url, data, options, success, error) {
    // 	var settings = $.extend({
    // 		url: url,
    // 		type: type,
    // 		dataType: 'json',
    // 		success: success,
    // 		error: error,
    // 		cache: false,
    // 		dataFilter: function (data, type) { return data || null; }
    // 	}, options);
    //
    // 	if (!$.isEmptyObject(data)) {
    // 		settings.data = data;
    // 	}
    // 	$.ajax(settings);
    // }

    // ----------------------------------------------------------------
    // Convenience method so we don't have to add the account and profile in every method
    function _addParamsToURL(url, list) {
        if (list && !$.isEmptyObject(list)) {
            for (var key in list) {
                url = url.replace("$$" + key + "$$", list[key]);
            }
        }

        return url;
    }

    // ----------------------------------------------------------------
    var _view = {
        // ------------------------------------------------------------
        selectOperatingSystem() {
            var v = "";

            v += "<header></header>";
            v += '<div class="content-body">';
            v += "	<h2>" + localize.t("login.mfa_setup.account_setup_title") + "</h2>";
            v +=
                "	<div>" +
                localize.t("login.mfa_setup.account_setup_description") +
                ' <a href="' +
                common.community.multi_factor_authentication_for_tealium +
                '" target="_blank" class="icon-question-sign" title="' +
                localize.t("login.mfa_setup.click_to_learn_more") +
                '"></a></div>';
            v += "	<h3>" + localize.t("login.mfa_setup.please_select_an_os") + "</h3>";
            v += "	<ul>";

            for (var i in _operating_systems) {
                v +=
                    '		<li><input type="radio" name="select-os" id="' +
                    _namespace +
                    "_os_" +
                    i +
                    '" value="' +
                    i +
                    '" ' +
                    (_selectedOS === i ? 'checked="checked"' : "") +
                    ' /><label for="' +
                    _namespace +
                    "_os_" +
                    i +
                    '"> ' +
                    _operating_systems[i].name +
                    "</label></li>";
            }

            v += "	</ul>";
            v += '<div id="contact-us">' + localize.t("login.mfa_setup.contact_account_manager") + "</div>";
            v += "</div>";
            v += '<div class="content-footer">';
            v +=
                '	<button class="btn" id="' +
                _namespace +
                '_selectOSCancelBtn">' +
                localize.t("tiq.dialog.button.cancel") +
                "</button>";
            v +=
                '	<button class="btn btn-primary pull-right" id="' +
                _namespace +
                '_selectOSNextBtn">' +
                localize.t("tiq.dialog.button.next") +
                "</button>";
            v += "</div>";

            return v;
        },

        // ------------------------------------------------------------
        setupMultiFactorAuth(qrCode) {
            var params = {};
            params.selectedOS = _operating_systems[_selectedOS];
            params.osName = params.selectedOS.name;
            params.appStore = localize.t("login.mfa_setup.download_from_app_store", {
                appStore: params.selectedOS.appStore
            });
            params.imgSrc = qrCode || "";

            if (params.osName == _operating_systems.windows.name) {
                return _view.windowsAuthenticator(params);
            }
            return _view.googleAuthenticator(params);
        },

        // ------------------------------------------------------------
        googleAuthenticator(data) {
            var v = "";

            v += "<header></header>";
            v += '<div class="content-body">';
            v += "	<div>";

            v += "		<h2>" + localize.t("login.mfa_setup.setup_google_authenticator") + "</h2>";
            v += "		<h3>" + localize.t("login.mfa_setup.install_google_authenticator_app", { os: data.osName }) + "</h3>";
            v += "		<ol>";
            v += "			<li>" + localize.t("login.mfa_setup.on_your_phone", { appStore: data.selectedOS.appStore }) + "</li>";
            v +=
                "			<li>" +
                localize.t("login.mfa_setup.search_for", {
                    url: '<a href="' + data.selectedOS.url + '" target="_blank">' + data.appStore + "</a>"
                }) +
                "</li>";
            v += "			<li>" + localize.t("login.mfa_setup.download_and_install") + "</li>";
            v += "		</ol>";
            v += "		<h3>" + localize.t("login.mfa_setup.now_open_and_configure") + "</h3>";
            v += "		<ol>";
            v += "			<li>" + localize.t("login.mfa_setup.setup_account") + "</li>";
            v += "			<li>" + localize.t("login.mfa_setup.scan_a_barcode") + "</li>";
            v += "			<li>" + localize.t("login.mfa_setup.use_your_phone") + "</li>";
            v += "		</ol>";
            v += "		<h3>" + localize.t("login.mfa_setup.once_you_have_scanned", { os: data.osName }) + "</h3>";
            v += "	</div>";
            v += '	<div id="' + _namespace + '_qr_code_img">';
            v +=
                '		<img alt="' +
                localize.t("login.mfa_setup.qr_code") +
                '" src="' +
                data.imgSrc +
                '" height="177" width="177">';
            v += "	</div>";
            v += "</div>";
            v += '<div class="content-footer">';
            v +=
                '	<button class="btn" id="' +
                _namespace +
                '_BackBtn">' +
                localize.t("tiq.dialog.button.back") +
                "</button>";
            v += '	<div class="pull-right">';
            v += '		<div class="formFieldInputError">' + localize.t("login.mfa_setup.validation.mfa_invalid") + "</div>";
            v +=
                '		<label for="' +
                _namespace +
                '_qr_code"><strong>' +
                localize.t("login.mfa_setup.code") +
                "</strong></label>";
            v += '		<input type="text" id="' + _namespace + '_qr_code" />';
            v +=
                '		<button class="btn btn-primary" id="' +
                _namespace +
                '_applyBtn">' +
                localize.t("tiq.dialog.button.verify_and_save") +
                "</button>";
            v += "	</div>";
            v += "</div>";

            return v;
        },

        // ------------------------------------------------------------
        windowsAuthenticator(data) {
            var v = "";

            v += "<header></header>";
            v += '<div class="content-body">';
            v += "	<div>";

            v += "		<h2>" + localize.t("login.mfa_setup.setup_authenticator") + "</h2>";
            v += "		<h3>" + localize.t("login.mfa_setup.install_authenticator_app", { os: data.osName }) + "</h3>";
            v += "		<ol>";
            v += "			<li>" + localize.t("login.mfa_setup.on_your_phone", { appStore: data.selectedOS.appStore }) + "</li>";
            v +=
                "			<li>" +
                localize.t("login.mfa_setup.search_for_windows", {
                    url: '<a href="' + data.selectedOS.url + '" target="_blank">' + data.appStore + "</a>"
                }) +
                "</li>";
            v += "			<li>" + localize.t("login.mfa_setup.download_and_install") + "</li>";
            v += "		</ol>";
            v += "		<h3>" + localize.t("login.mfa_setup.now_open_and_configure_windows") + "</h3>";
            v += "		<ol>";
            v += "			<li>" + localize.t("login.mfa_setup.setup_account_windows") + "</li>";
            v += "			<li>" + localize.t("login.mfa_setup.click_camera_icon") + "</li>";
            v += "			<li>" + localize.t("login.mfa_setup.use_your_phone") + "</li>";
            v += "		</ol>";
            v += "		<h3>" + localize.t("login.mfa_setup.once_you_have_scanned", { os: data.osName }) + "</h3>";
            v += "	</div>";
            v += '	<div id="' + _namespace + '_qr_code_img">';
            v +=
                '		<img alt="' +
                localize.t("login.mfa_setup.qr_code") +
                '" src="' +
                data.imgSrc +
                '" height="177" width="177">';
            v += "	</div>";
            v += "</div>";
            v += '<div class="content-footer">';
            v +=
                '	<button class="btn" id="' +
                _namespace +
                '_BackBtn">' +
                localize.t("tiq.dialog.button.back") +
                "</button>";
            v += '	<div class="pull-right">';
            v += '		<div class="formFieldInputError">' + localize.t("login.mfa_setup.validation.mfa_invalid") + "</div>";
            v +=
                '		<label for="' +
                _namespace +
                '_qr_code"><strong>' +
                localize.t("login.mfa_setup.code") +
                "</strong></label>";
            v += '		<input type="text" id="' + _namespace + '_qr_code" />';
            v +=
                '		<button class="btn btn-primary" id="' +
                _namespace +
                '_applyBtn">' +
                localize.t("tiq.dialog.button.verify_and_save") +
                "</button>";
            v += "	</div>";
            v += "</div>";

            return v;
        },

        // ------------------------------------------------------------
        setupComplete() {
            var v = "";

            v += "<header></header>";
            v += '<div class="content-body">';
            v += '	<div class="mfa-setup-complete">';
            v += "		<h2>" + localize.t("login.mfa_setup.setup_successful") + "</h2>";
            v +=
                "		<h3>" +
                localize.t("login.mfa_setup.successfully_configured", {
                    timer: '<span class="countdown_timer">' + _countdownSeconds + "</span>"
                }) +
                "</h3>";
            v +=
                '		<div><a href="javascript:;" onclick="utui.mfaSetup.redirect(); return false;">' +
                localize.t("login.mfa_setup.click_here_to_redirect_now") +
                "</a></div>";
            v += "	</div>";
            v += "</div>";

            return v;
        }
    };

    // ----------------------------------------------------------------
    var _request = {
        // ----------------------------------------------------------------
        getQRCode(callback) {
            var email = $("#email").val();
            var pw = $("#password").val();
            var url = _addParamsToURL(_url.mfa_setup, { username: email });
            var qrCode;

            _sendRequest(
                "POST",
                url,
                { password: pw },
                {},
                function () {
                    qrCode = _response.onGetQRCodeSuccess.apply(this, arguments);
                    if (callback) callback(qrCode);
                },
                function () {
                    qrCode = _response.onGetQRCodeError.apply(this, arguments);
                    if (callback) callback(qrCode);
                }
            );
        },

        // ----------------------------------------------------------------
        verifyToken() {
            var email = $("#email").val();
            var pw = $("#password").val();
            var token = $("#" + _namespace + "_qr_code").val();
            var url = _addParamsToURL(_url.mfa_verify, { username: email });

            _sendRequest(
                "POST",
                url,
                { code: token, password: pw },
                {},
                _response.onVerifyTokenSuccess,
                _response.onVerifyTokenError
            );
        }
    };

    // ----------------------------------------------------------------
    var _response = {
        // ----------------------------------------------------------------
        onGetQRCodeSuccess(data) {
            if (data) {
                return data.qr_code;
            }
            return "";
        },

        // ----------------------------------------------------------------
        onGetQRCodeError(status, response) {
            return "";
        },

        // ----------------------------------------------------------------
        onVerifyTokenSuccess(data) {
            // Proceed to setup complete screen
            $("#mfaSetup").html(_view.setupComplete());
            _countdownTimer("mfaSetup", _countdownSeconds, utui.mfaSetup.redirect);
        },

        // ----------------------------------------------------------------
        onVerifyTokenError(status, response) {
            $(".content-footer").addClass("errorMsg");
        }
    };

    // ----------------------------------------------------------------
    var _eventHandlers = {
        // ----------------------------------------------------------------
        onSelectOSNextBtnClick(event) {
            _selectedOS = $("input[name=select-os]:checked").val();

            // Do not continue any further
            if (_selectedOS === "none") {
                return;
            }

            _request.getQRCode(function (qrCode) {
                $("#mfaSetup").html(_view.setupMultiFactorAuth(qrCode));
                _bindUI.setupMultiFactorAuth();
            });
        },

        // ----------------------------------------------------------------
        onSelectOSCancelBtnClick(event) {
            _selectedOS = "android"; // reset

            // Remove the mfa_setup
            utui.util.storage.removeLocal("mfaRequired");

            utui.mfaSetup.redirect();
        },

        // ----------------------------------------------------------------
        onSelectOSRadioChange(event) {
            var selectedOS = $(this).val();

            if (selectedOS === "none") {
                $("#" + _namespace + "_selectOSNextBtn").addClass("ui-state-disabled");
                $("#contact-us").slideDown();
            } else {
                $("#" + _namespace + "_selectOSNextBtn").removeClass("ui-state-disabled");
                $("#contact-us").slideUp();
            }
        },

        // ----------------------------------------------------------------
        onBackBtnClick(event) {
            $("#mfaSetup").html(_view.selectOperatingSystem());
            _bindUI.selectOperatingSystem();
        },

        // ----------------------------------------------------------------
        onApplyBtnClick(event) {
            _request.verifyToken();
        }
    };

    // ----------------------------------------------------------------
    var _bindUI = {
        // ------------------------------------------------------------
        selectOperatingSystem() {
            $("#" + _namespace + "_selectOSNextBtn")
                .off("click")
                .on("click", _eventHandlers.onSelectOSNextBtnClick);
            $("#" + _namespace + "_selectOSCancelBtn")
                .off("click")
                .on("click", _eventHandlers.onSelectOSCancelBtnClick);
            $("input[name=select-os]").off("change").on("change", _eventHandlers.onSelectOSRadioChange);
            $("#mfaSetup").find("[title]").tipsy({ gravity: "w" });
        },

        // ------------------------------------------------------------
        setupMultiFactorAuth() {
            $("#" + _namespace + "_applyBtn")
                .off("click")
                .on("click", _eventHandlers.onApplyBtnClick);
            $("#" + _namespace + "_BackBtn")
                .off("click")
                .on("click", _eventHandlers.onBackBtnClick);
        }
    };

    // Public API
    // ----------------------------------------------------------------
    var _public = {
        init: _init,
        redirect: _redirect,
        showMoreInfo(event) {
            //TODO: mco show/hide more info
        }
    };

    return _public;
})();
